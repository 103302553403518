import CircledBackButton from "@/components/CircledBackButton";
import IconLoader from "@/components/IconLoader";
import LoadingSpinner from "@/components/LoadingSpinner";
import MaxWidthContainer from "@/components/MaxWidthContainer";
import WatermarkIcon from "@/components/WatermarkIcon";
import useGetDoctorList from "@/hooks/useGetDoctorList";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { Doctor } from "@/services/core-api-adapter";
import theme, { shadows } from "@/theme";
import { convertPxToRem } from "@/utils";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FindADoctorResults from "./components/results";

interface FindADoctorProps {
  onBackButton: () => void;
}

interface SelectedProvinceProps {
  selectedProvince: string;
}

function SelectedProvince({ selectedProvince }: SelectedProvinceProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="row">
      <WatermarkIcon
        extra={{
          alignSelf: "center",
        }}
        height={35}
        width={35}
        iconSize="medium"
        color={theme.palette.accent2.light}
        foreground={theme.palette.primary[700]}
        Icon={"MapMarkerIcon"}
      />
      <Stack ml={2} spacing={1}>
        <Typography
          variant="body2"
          fontWeight={600}
          fontSize={convertPxToRem(10)}
          color={theme.palette.neutral[700]}
        >
          {t("FindADoctor.selectedProvinceLabel")}
        </Typography>
        <Typography fontSize={convertPxToRem(16)} variant="h2">
          {selectedProvince}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default function FindADoctor({ onBackButton }: FindADoctorProps) {
  const { t } = useTranslation();
  const [selectedProvinceValue, setSelectedProvinceValue] = useState("");
  const [shouldShowResults, setShouldShowResults] = useState(false);
  const [shouldShowProvinces, setShouldShowProvinces] = useState(true);
  const [doctorSearchResults, setDoctorSearchResults] = useState({});
  const [provincesList, setProvincesList] = useState<string[]>([]);

  const { doctorList, doctorListFetchError, isDoctorListLoading } =
    useGetDoctorList();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Find a doctor",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Find a doctor",
      pageCategory: "Medical assistance",
    });
  }, []);

  function sortDoctorsByProvince(
    doctors: Doctor[],
    selectedProvince: string
  ): Doctor[] {
    return doctors.filter((doctor) => doctor.province === selectedProvince);
  }

  function getProvinceNames(doctors: Doctor[]): string[] {
    const provinceNames: string[] = [];

    doctors?.forEach((doctor) => {
      if (!provinceNames.includes(doctor.province)) {
        provinceNames.push(doctor.province);
      }
    });

    return provinceNames;
  }

  const handleProvinceSelectionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedProvinceValue((event.target as HTMLInputElement).value);
  };

  function onHandleCancelButtonClick() {
    if (selectedProvinceValue && !shouldShowResults) {
      setSelectedProvinceValue("");
    }

    if (selectedProvinceValue && Object.keys(doctorSearchResults).length) {
      return setShouldShowResults(false);
    }
  }

  function onContinueButtonClick() {
    if (selectedProvinceValue) {
      setShouldShowResults(true);
      setShouldShowProvinces(false);

      const sortedDoctors = sortDoctorsByProvince(
        doctorList,
        selectedProvinceValue
      );
      setDoctorSearchResults(sortedDoctors);
    }
  }

  function onBackButtonClick() {
    if (shouldShowResults) {
      setShouldShowResults(false);
      setShouldShowProvinces(true);
      setSelectedProvinceValue("");
      return;
    }
    onBackButton();
  }

  useMemo(() => {
    if (doctorList && doctorList.length > 0) {
      const provinces = getProvinceNames(doctorList);
      setProvincesList(provinces);
      setShouldShowProvinces(true);
    }
  }, [doctorList]);

  if (!isDoctorListLoading && doctorListFetchError) {
    return (
      <Typography textAlign="center">
        {t("common.somethingWentWrong")}
      </Typography>
    );
  }

  return (
    <>
      <Stack
        color="neutral.dark"
        pb={12}
        sx={{
          height: "100%",
        }}
      >
        <Box p={2}>
          <Stack>
            <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
          </Stack>

          <Stack>
            <IconLoader
              icon="ChatWithMapAndUnuIcon"
              color="primary"
              sx={{
                fontSize: convertPxToRem(150),
                alignSelf: "center",
              }}
            />

            <Stack textAlign="center" spacing={2} py={4}>
              <Typography variant="h1" color="inherit">
                {t(`FindADoctor.title`)}
              </Typography>

              <Typography variant="h3" color="inherit" px={2}>
                {shouldShowResults
                  ? t("FindADoctor.selectSuburbLabel")
                  : t(`FindADoctor.subTitle`)}
              </Typography>

              <Divider sx={{ pt: 2 }} />
            </Stack>
          </Stack>
        </Box>

        {isDoctorListLoading && (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        )}
        {!isDoctorListLoading && (
          <Stack spacing={2}>
            <Stack px={2} spacing={4}>
              {shouldShowResults ? (
                <SelectedProvince selectedProvince={selectedProvinceValue} />
              ) : (
                <Typography
                  fontWeight="600"
                  color="neutral.700"
                  variant="body2"
                >
                  {t(`FindADoctor.provincesLabel`)}
                </Typography>
              )}

              {shouldShowResults && (
                <Stack pb={{ xs: convertPxToRem(50), md: 0 }}>
                  <FindADoctorResults
                    doctorSearchResults={doctorSearchResults as any[]}
                  />
                </Stack>
              )}
            </Stack>

            {shouldShowProvinces && (
              <Stack spacing={2} px={4} pb={{ xs: convertPxToRem(100), md: 0 }}>
                {provincesList.map((provinceText, index) => (
                  <FormControl key={String(provinceText) + String(index)}>
                    <RadioGroup
                      aria-labelledby={t(`FindADoctor.provincesLabel`)}
                      value={selectedProvinceValue}
                      onChange={handleProvinceSelectionChange}
                    >
                      <FormControlLabel
                        value={provinceText}
                        control={<Radio />}
                        label={
                          <Typography fontWeight="500" color="neutral.700">
                            {provinceText}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                ))}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      {!shouldShowResults && (
        <Stack
          direction={"row"}
          spacing={1}
          p={2}
          bgcolor="background.paper"
          width="100%"
          justifyContent="center"
          textAlign="center"
          alignItems="center"
          left="0"
          bottom="0"
          position="fixed"
          sx={{
            boxShadow: shadows.heavy,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
          }}
        >
          <MaxWidthContainer>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Button
                onClick={onHandleCancelButtonClick}
                color="primary"
                variant="outlined"
                fullWidth
              >
                {t("common.cancelButton")}
              </Button>
              <Button
                disabled={!selectedProvinceValue}
                onClick={onContinueButtonClick}
                fullWidth
              >
                {t("common.continueButton")}
              </Button>
            </Stack>
          </MaxWidthContainer>
        </Stack>
      )}
    </>
  );
}
