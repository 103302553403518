import { DoctorOnDemandPrompt } from ".";

export default function DoctorOnDemandPromptExamples() {
  function onContinueButtonClick() {
    console.log("Continue button clicked");
  }

  function onBackButtonClick() {
    console.log("Back button clicked");
  }

  function onFindADoctorButtonClick() {
    console.log("More info button clicked");
  }

  return (
    <>
      <DoctorOnDemandPrompt
        onContinueButtonClick={onContinueButtonClick}
        onBackButtonClick={onBackButtonClick}
        onFindADoctorButtonClick={onFindADoctorButtonClick}
      />
    </>
  );
}
