import { assign, fromPromise, setup } from "xstate";

import {
  MemberConsultationOutcome,
  getMemberConsultations,
  startMemberConsultation,
  getMemberConsultationOutcome,
  GetMemberConsultationsItem,
  getMemberships,
  Member,
  MembershipType,
  submitRating,
  UserRating,
  ServiceRating,
  MemberConsultationType,
  ProductAdministratorSupportedServiceChannelAvailability,
  getMemberConsultationRates,
  GetMemberConsultationRatesItem,
  MemberConsultationStatus,
  getDefaultPayGMedicalAssistanceAvailabilityData,
} from "../../services/core-api-adapter";
import {
  isFeatureEnabled,
  FeatureToggleIdentifier,
} from "../../services/feature-toggle-adapter";
import { getCurrentFormattedDay, getCurrentFormattedTime } from "../../utils";

const MAX_POLLING_TIME_IN_SECONDS =
  Number(
    import.meta.env.VITE_APP_INTERCARE_MEDICAL_ASSISTANCE_MAX_POLLING_TIME
  ) || 100;
const POLLING_INTERVAL_IN_MILLISECONDS =
  Number(
    import.meta.env.VITE_APP_INTERCARE_MEDICAL_ASSISTANCE_POLLING_INTERVAL
  ) || 2000;

interface Context {
  activeConsultationListResponse: Array<GetMemberConsultationsItem>;
  membersList: Array<Member>;
  selectedDependent: Member | null;
  selectedConsultation: GetMemberConsultationsItem | null;
  consultationOutcomeResponse: GetMemberConsultationsItem | null;
  consultationRatesResponse: GetMemberConsultationRatesItem[] | null;
  consultationType: MemberConsultationType;
  userRating: UserRating;
  consultationAvailabilityData: ProductAdministratorSupportedServiceChannelAvailability[];
  startConsultationTime: Date | null;
  queryParamConsultationId: string;
  primarySymptomIds: string[];
  isDoctorOnDemand: boolean;
}

const defaultAvailabilityData =
  getDefaultPayGMedicalAssistanceAvailabilityData();

const initialContextValues: Context = {
  activeConsultationListResponse: [],
  membersList: [],
  selectedDependent: null,
  queryParamConsultationId: "",
  selectedConsultation: null,
  consultationOutcomeResponse: null,
  consultationRatesResponse: null,
  consultationType: MemberConsultationType.SYMPTOM_ASSESSMENT,
  userRating: {
    rating: 0,
    comment: "",
    serviceType: ServiceRating.CONSULTATION,
    ratedServiceId: "",
  },
  consultationAvailabilityData: defaultAvailabilityData,
  startConsultationTime: null,
  isDoctorOnDemand: false,
  primarySymptomIds: [],
};

export const eventNames = {
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  NEXT_BUTTON_CLICKED: "NEXT_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  MORE_INFO_BUTTON_CLICKED: "MORE_INFO_BUTTON_CLICKED",
  START_CONSULTATION_BUTTON_CLICKED: "START_CONSULTATION_BUTTON_CLICKED",
  DEPENDANT_SELECTED: "DEPENDANT_SELECTED",
  ACTIVE_CONSULTATION_SELECTED: "ACTIVE_CONSULTATION_SELECTED",
  USER_REDIRECTED: "USER_REDIRECTED",
  RATING_TRY_AGAIN: "RATING_TRY_AGAIN",
  DOCTOR_ON_DEMAND_BUTTON_CLICKED: "DOCTOR_ON_DEMAND_BUTTON_CLICKED",
  FIND_A_DOCTOR_BUTTON_CLICKED: "FIND_A_DOCTOR_BUTTON_CLICKED",
  NURSE_CONSULTATION_BUTTON_CLICKED: "NURSE_CONSULTATION_BUTTON_CLICKED",
};

export const intercareFlowStateMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addActiveConsultationListResponseToContext: assign(({ event }) => {
      return { activeConsultationListResponse: event.output };
    }),
    addMembersListToContext: assign(({ event }) => {
      return { membersList: event.output.memberships[0].members };
    }),
    addSelectedDependentToContext: assign(({ event }) => {
      return { selectedDependent: event.input };
    }),
    addConsultationOutcomeResponseToContext: assign(({ event }) => {
      return { consultationOutcomeResponse: event.input || event.output };
    }),
    addSelectedConsultationToContext: assign(({ event }) => {
      return { selectedConsultation: event.input };
    }),
    addIsDoctorOnDemandToContext: assign(() => {
      return { isDoctorOnDemand: true };
    }),
    removeIsDoctorOnDemandToContext: assign(() => {
      return { isDoctorOnDemand: false };
    }),
    addStartConsultationResponseToContext: assign(({ event }) => {
      return { selectedConsultation: event.input || event.output };
    }),
    addUserRatingToContext: assign(({ event }) => {
      return { userRating: event.input || event.output };
    }),
    addConsultationRatesResponseToContext: assign(({ event }) => {
      return { consultationRatesResponse: event.output };
    }),
  },
  actors: {
    getActiveConsultations: fromPromise(async () => {
      return await getMemberConsultations();
    }),
    getMemberships: fromPromise(async () => {
      return await getMemberships();
    }),
    getConsultationOutcome: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const consultationId =
          input.context.selectedConsultation?.consultationId ||
          input.context.queryParamConsultationId;
        return await getMemberConsultationOutcome(consultationId);
      }
    ),
    getMemberConsultationRates: fromPromise(async () => {
      return await getMemberConsultationRates();
    }),
    startConsultation: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const selectedMemberId =
          input.context.selectedDependent?.memberDetails?.id ||
          input.context.membersList.find(
            (member) =>
              member.membershipDetails.type === MembershipType.MAIN_MEMBER
          )?.memberDetails?.id ||
          "";

        let consultationType: MemberConsultationType =
          input.context.consultationType;
        let primarySymptomIds: string[] = [];
        if (input.context.isDoctorOnDemand) {
          consultationType = MemberConsultationType.DIGITAL_DOCTOR_CONSULT;
        }

        if (input.context.primarySymptomIds) {
          primarySymptomIds = input.context.primarySymptomIds;
        }

        return await startMemberConsultation(
          consultationType,
          selectedMemberId,
          primarySymptomIds
        );
      }
    ),
    submitRating: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return await submitRating({
          rating: input.context.userRating.rating,
          comment: input.context.userRating?.comment || "",
          serviceType:
            input.context.userRating?.serviceType || ServiceRating.CONSULTATION,
          ratedServiceId: input.context.selectedConsultation?.consultationId,
        });
      }
    ),
  },
  guards: {
    hasConsultationIdinQueryParam: function ({ context }) {
      return context.queryParamConsultationId !== "";
    },
    hasPaymentSucceededAndIsDoctorOnDemand: function ({ context }) {
      return Boolean(
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.PAYMENT_SUCCEEDED &&
          context.isDoctorOnDemand
      );
    },
    isOutsideOperatingHours: function ({ context }) {
      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      const isOutsideOperatingHours =
        currentTime < openingTime || currentTime > closingTime;
      return isOutsideOperatingHours;
    },
    hasNoActiveConsultationsAndIsOutsideOperatingHours: function ({ context }) {
      const hasActiveConsultations =
        context.activeConsultationListResponse.length > 0;

      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      const isOutsideOperatingHours =
        currentTime < openingTime || currentTime > closingTime;
      return isOutsideOperatingHours && !hasActiveConsultations;
    },
    hasAtLeastOneActiveConsultations: function ({ context }) {
      return context.activeConsultationListResponse.length > 0;
    },
    hasDependentsAndNoActiveConsultations: function ({ context }) {
      return (
        context.activeConsultationListResponse.length === 0 &&
        context.membersList.length > 1
      );
    },
    hasNoDependentsAndNoActiveConsultations: function ({ context }) {
      const dependentsListWithoutMainMember = context.membersList.filter(
        (member) => member.membershipDetails.type !== MembershipType.MAIN_MEMBER
      );

      return (
        context.activeConsultationListResponse.length === 0 &&
        dependentsListWithoutMainMember.length === 0
      );
    },
    hasActiveConsultationsAndIsOutsideOperatingHours: function ({ context }) {
      const hasActiveConsultations =
        context.activeConsultationListResponse.length > 0;

      const currentTime = getCurrentFormattedTime();
      const currentDay = getCurrentFormattedDay();

      const operatingHours = context.consultationAvailabilityData.find(
        (day) => day.day === currentDay
      );
      const [openingTime, closingTime] = operatingHours?.operatingHours.split(
        " - "
      ) as any;

      return (
        (currentTime <= openingTime || currentTime >= closingTime) &&
        hasActiveConsultations
      );
    },
    hasDependents: function ({ context }) {
      return context.membersList.length > 1;
    },
    selectedConsultationHasOutstandingPayment: function ({ context }) {
      const { chargeAmountFormatted, chargeCode, outcome } =
        context.selectedConsultation as any;

      return Boolean(
        (outcome === MemberConsultationOutcome.PAYMENT_REQUESTED ||
          outcome === MemberConsultationOutcome.PAYMENT_FAILED) &&
          chargeAmountFormatted &&
          chargeCode
      );
    },
    selectedConsultationHasOutstandingRefund: function ({ context }) {
      return (
        context.selectedConsultation?.outcome ===
        MemberConsultationOutcome.REFUND_REQUESTED
      );
    },
    selectedConsultationHasOutstandingPaymentWithNoCharge: function ({
      context,
    }) {
      const { chargeAmountFormatted, chargeCode, outcome } =
        context.selectedConsultation as any;
      return Boolean(
        (outcome === MemberConsultationOutcome.PAYMENT_REQUESTED ||
          outcome === MemberConsultationOutcome.PAYMENT_FAILED) &&
          (!chargeAmountFormatted || !chargeCode)
      );
    },
    userHasFindAHospitalOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.EMERGENCY_CONSULT
      );
    },
    userHasArchivedConsultation: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.status ===
        MemberConsultationStatus.ARCHIVED
      );
    },
    userHasUnsuccessfulPaymentOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_FAILED
      );
    },
    userHasFindADoctorOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.INPERSON_CONSULT
      );
    },
    activeUserHasCompletedOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.DIGITAL_CONSULT_ENDED
      );
    },
    selectedConsultationHasNoRefund: function ({ context }) {
      return (
        context.selectedConsultation?.outcome ===
        MemberConsultationOutcome.NO_REFUND
      );
    },
    hasActiveConsultations: function ({ context }) {
      return context.activeConsultationListResponse.length > 0;
    },
    userHasCompletedOutcome: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.DIGITAL_CONSULT_ENDED ||
        context.consultationOutcomeResponse?.outcome ===
          MemberConsultationOutcome.NO_REFUND
      );
    },
    userHasOutstandingRefund: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.REFUND_REQUESTED
      );
    },
    timeoutExceeded: function ({ context }) {
      const currentDate = new Date();
      const consultationStartTime = context.startConsultationTime;

      if (consultationStartTime) {
        return (
          Number(currentDate.getTime() - consultationStartTime.getTime()) /
            1000 >
          MAX_POLLING_TIME_IN_SECONDS
        );
      }

      return false;
    },
    userHasNotMadePayment: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_REQUESTED
      );
    },
    userHasMadePayment: function ({ context }) {
      return (
        context.consultationOutcomeResponse?.outcome ===
        MemberConsultationOutcome.PAYMENT_SUCCEEDED
      );
    },
    isDoctorOnDemandFeatureEnabledAndHasNoActiveConsultations: function ({
      context,
    }) {
      return (
        isFeatureEnabled(FeatureToggleIdentifier.ENABLE_DOCTOR_ON_DEMAND) &&
        context.activeConsultationListResponse.length === 0
      );
    },
    isDoctorOnDemandFeatureEnabled: function () {
      return isFeatureEnabled(FeatureToggleIdentifier.ENABLE_DOCTOR_ON_DEMAND);
    },
    isDoctorOnDemandSelected: function ({ context }) {
      return context.isDoctorOnDemand;
    },
    hasPrimarySymptoms: function ({ context }) {
      return context.primarySymptomIds.length > 0;
    },
  },
  schemas: {},
  delays: {
    CONSULTATION_INTERVAL: POLLING_INTERVAL_IN_MILLISECONDS,
  },
}).createMachine({
  context: initialContextValues,
  id: "intercareFlow",
  initial: "start",
  states: {
    start: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        const { primarySymptomIds = [], queryParamConsultationId = "" } = event
          .input?.context
          ? event.input.context
          : {};
        return {
          ...entryContext,
          queryParamConsultationId,
          primarySymptomIds,
        };
      }),
      invoke: {
        id: "start",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "fetchingMemberDetails",
          actions: {
            type: "addActiveConsultationListResponseToContext",
          },
        },
        onError: {
          target: "getActiveConsultationsError",
        },
        src: "getActiveConsultations",
      },
    },
    fetchingMemberDetails: {
      invoke: {
        id: "fetchingMemberDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "selectConsultationTypeOrDefault",
          actions: {
            type: "addMembersListToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getMemberships",
      },
    },
    selectConsultationTypeOrDefault: {
      always: [
        {
          target: "promptingDoctorOnDemandNextStepsOrSelectingDependents",
          guard: {
            type: "hasPrimarySymptoms",
          },
          actions: [{ type: "addIsDoctorOnDemandToContext" }],
        },
        {
          target: "selectConsultationType",
          guard: {
            type: "isDoctorOnDemandFeatureEnabledAndHasNoActiveConsultations",
          },
        },
        {
          target: "selectingActiveConsultationOrStartingNewConsultation",
        },
      ],
    },
    selectConsultationType: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.DOCTOR_ON_DEMAND_BUTTON_CLICKED]: {
          target: "promptingDoctorOnDemandNextStepsOrSelectingDependents",
          actions: [{ type: "addIsDoctorOnDemandToContext" }],
        },
        [eventNames.NURSE_CONSULTATION_BUTTON_CLICKED]: {
          target: "promptingNurseConsultNextStepsOrSelectingDependents",
        },
      },
    },
    promptingNurseConsultNextStepsOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "isOutsideOperatingHours",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "promptingNextSteps",
        },
      ],
    },
    promptingDoctorOnDemandNextStepsOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "isOutsideOperatingHours",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "doctorOnDemandPromptingNextSteps",
        },
      ],
    },
    doctorOnDemandPromptingNextSteps: {
      on: {
        [eventNames.FIND_A_DOCTOR_BUTTON_CLICKED]: {
          target: "findADoctor",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "startingConsultation",
          actions: [{ type: "addIsDoctorOnDemandToContext" }],
        },
        [eventNames.BACK_BUTTON_CLICKED]: [
          { target: "exit", guard: { type: "hasPrimarySymptoms" } },
          {
            target: "selectConsultationType",
            guard: {
              type: "isDoctorOnDemandFeatureEnabled",
            },
            actions: [{ type: "removeIsDoctorOnDemandToContext" }],
          },
          {
            target: "selectConsultationTypeOrDefault",
          },
        ],
      },
    },
    selectingActiveConsultationOrStartingNewConsultation: {
      always: [
        {
          target: "fetchingConsultationOutcome",
          guard: {
            type: "hasConsultationIdinQueryParam",
          },
        },
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasNoActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasAtLeastOneActiveConsultations",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "promptingNextSteps",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
      ],
    },
    selectingActiveConsultation: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.ACTIVE_CONSULTATION_SELECTED]: {
          target: "activeConsultationOutcome",
          actions: [
            { type: "addSelectedConsultationToContext" },
            { type: "addConsultationOutcomeResponseToContext" },
          ],
        },
        [eventNames.START_CONSULTATION_BUTTON_CLICKED]: {
          target: "promptingNextStepsOrSelectingDependents",
        },
      },
    },
    promptingNextStepsOrSelectingDependents: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "selectConsultationType",
          guard: {
            type: "isDoctorOnDemandFeatureEnabled",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependents",
          },
        },
        {
          target: "promptingNextSteps",
        },
      ],
    },
    activeConsultationOutcome: {
      always: [
        {
          target: "outsideOperatingHours",
          guard: {
            type: "hasActiveConsultationsAndIsOutsideOperatingHours",
          },
        },
        {
          target: "outstandingPayment",
          guard: {
            type: "selectedConsultationHasOutstandingPayment",
          },
        },
        {
          target: "outstandingRefund",
          guard: {
            type: "selectedConsultationHasOutstandingRefund",
          },
        },
        {
          target: "promptingNextSteps",
          guard: {
            type: "selectedConsultationHasOutstandingPaymentWithNoCharge",
          },
        },
        {
          target: "findAHospital",
          guard: {
            type: "userHasFindAHospitalOutcome",
          },
        },
        {
          target: "paymentFailure",
          guard: {
            type: "userHasUnsuccessfulPaymentOutcome",
          },
        },
        {
          target: "promptingFindADoctor",
          guard: {
            type: "userHasFindADoctorOutcome",
          },
        },
        {
          target: "serviceRating",
          guard: {
            type: "activeUserHasCompletedOutcome",
          },
        },
        {
          target: "exit",
          guard: {
            type: "selectedConsultationHasNoRefund",
          },
        },
      ],
      on: {
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
      },
    },
    outstandingPayment: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "selectingActiveConsultation",
        },
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    promptingNextSteps: {
      always: {
        target: "outsideOperatingHours",
        guard: {
          type: "hasActiveConsultationsAndIsOutsideOperatingHours",
        },
      },
      invoke: {
        id: "promptingNextSteps",
        input: ({ context }) => ({ context }),
        onDone: {
          actions: {
            type: "addConsultationRatesResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getMemberConsultationRates",
      },
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "redirectFromPromptingNextSteps",
        },
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "startingConsultation",
        },
        [eventNames.MORE_INFO_BUTTON_CLICKED]: {
          target: "promptingMoreInfo",
        },
      },
    },
    promptingMoreInfo: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "promptingNextSteps",
        },
      },
    },
    redirectFromPromptingNextSteps: {
      always: [
        {
          target: "exit",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "selectConsultationType",
          guard: {
            type: "isDoctorOnDemandFeatureEnabled",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasActiveConsultations",
          },
        },
        {
          target: "selectingDependents",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
      ],
    },
    selectingDependents: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.DEPENDANT_SELECTED]: {
          target: "promptingDoctorOnDemandOrNurseConsultNextSteps",
          actions: "addSelectedDependentToContext",
        },
      },
    },
    promptingDoctorOnDemandOrNurseConsultNextSteps: {
      always: [
        {
          target: "doctorOnDemandPromptingNextSteps",
          guard: {
            type: "isDoctorOnDemandSelected",
          },
        },
        {
          target: "promptingNextSteps",
        },
      ],
    },
    getActiveConsultationsError: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    startingConsultation: {
      invoke: {
        id: "startingConsultation",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "breakoutDisclaimer",
          actions: {
            type: "addStartConsultationResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "startConsultation",
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    breakoutDisclaimer: {
      on: {
        [eventNames.USER_REDIRECTED]: {
          target: "waitingForConsultationOutcome",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "redirectFromBreakoutDisclaimer",
        },
      },
    },
    redirectFromBreakoutDisclaimer: {
      always: [
        {
          target: "promptingNextSteps",
          guard: {
            type: "hasNoDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "promptingNextSteps",
          guard: {
            type: "hasDependentsAndNoActiveConsultations",
          },
        },
        {
          target: "selectingActiveConsultation",
          guard: {
            type: "hasActiveConsultations",
          },
        },
      ],
    },
    waitingForConsultationOutcome: {
      after: {
        CONSULTATION_INTERVAL: {
          target: "fetchingConsultationOutcome",
        },
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    fetchingConsultationOutcome: {
      invoke: {
        id: "fetchingConsultationOutcome",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "processingConsultationOutcome",
          actions: {
            type: "addConsultationOutcomeResponseToContext",
          },
        },
        onError: {
          target: "genericError",
        },
        src: "getConsultationOutcome",
      },
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    processingConsultationOutcome: {
      always: [
        {
          target: "promptingNextSteps",
          guard: {
            type: "userHasArchivedConsultation",
          },
        },
        {
          target: "findAHospital",
          guard: {
            type: "userHasFindAHospitalOutcome",
          },
        },
        {
          target: "paymentFailure",
          guard: {
            type: "userHasUnsuccessfulPaymentOutcome",
          },
        },
        {
          target: "promptingFindADoctor",
          guard: {
            type: "userHasFindADoctorOutcome",
          },
        },
        {
          target: "serviceRating",
          guard: {
            type: "userHasCompletedOutcome",
          },
        },
        {
          target: "outstandingRefund",
          guard: {
            type: "userHasOutstandingRefund",
          },
        },
        {
          target: "exit",
          guard: {
            type: "timeoutExceeded",
          },
        },
        {
          target: "breakoutDisclaimer",
          guard: {
            type: "hasConsultationIdinQueryParam",
          },
        },
        {
          target: "waitingForConsultationOutcome",
        },
      ],
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    genericError: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    outsideOperatingHours: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    checkConsultationOperatingHoursSuccess: {
      always: [
        {
          target: "noPaymentMadeRedirect",
          guard: {
            type: "userHasNotMadePayment",
          },
        },
        {
          target: "promptingNextSteps",
          guard: {
            type: "userHasMadePayment",
          },
        },
      ],
    },
    submittingUserRating: {
      invoke: {
        id: "submittingUserRating",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "submitRatingComplete",
        },
        onError: {
          target: "submitRatingError",
        },
        src: "submitRating",
      },
    },
    submitRatingComplete: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    submitRatingError: {
      on: {
        [eventNames.RATING_TRY_AGAIN]: {
          target: "serviceRating",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    outstandingRefund: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "exit",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    serviceRating: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "submittingUserRating",
          actions: "addUserRatingToContext",
        },
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    findAHospital: {
      type: "final",
    },
    promptingFindADoctor: {
      on: {
        [eventNames.CONTINUE_BUTTON_CLICKED]: {
          target: "findADoctor",
        },
        [eventNames.BACK_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    findADoctor: {
      on: {
        [eventNames.BACK_BUTTON_CLICKED]: [
          {
            target: "doctorOnDemandPromptingNextSteps",
            guard: {
              type: "isDoctorOnDemandSelected",
            },
          },
          { target: "promptingFindADoctor" },
        ],
      },
    },
    paymentFailure: {
      on: {
        [eventNames.GO_HOME_BUTTON_CLICKED]: {
          target: "exit",
        },
      },
    },
    noPaymentMadeRedirect: {},
    exit: {
      type: "final",
    },
  },
});
