import { Prompt } from ".";

export default function IntercareMedicalAssistancePromptExamples() {
  function onContinueButtonClick() {
    console.log("Continue button clicked");
  }

  function onBackButtonClick() {
    console.log("Back button clicked");
  }

  return (
    <>
      <Prompt
        onContinueButtonClick={onContinueButtonClick}
        onBackButtonClick={onBackButtonClick}
      />
    </>
  );
}
