import DefaultDialog from "@/components/DefaultDialog";
import FullScreenIframeDialog from "@/components/FullScreenIframeDialog";
import IconLoader from "@/components/IconLoader";
import {
  trackEvent,
  trackPageViewV2,
  trackUserInteraction,
} from "@/services/analytics-adapter";
import { generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage } from "@/services/core-api-adapter";
import { convertPxToRem } from "@/utils";
import { Button, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";


interface BreakoutProps {
  redirectUrl: string | undefined;
  onConfirm: () => void;
  onDecline: () => void;
}

interface BreakoutDisclaimerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function BreakoutDisclaimerModal({
  isOpen,
  onClose,
}: BreakoutDisclaimerModalProps) {
  const { t } = useTranslation();

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "IntercareBreakoutDisclaimer",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }
  return (
    <DefaultDialog
      label={t("IntercareBreakout.disclaimer.modal.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600}>
          {t("IntercareBreakout.disclaimer.modal.title")}
        </Typography>

        <Typography variant="body2" color="inherit">
          {t("IntercareBreakout.disclaimer.modal.body")}
        </Typography>

        <Typography variant="body2" color="inherit">
          <Trans i18nKey="IntercareBreakout.disclaimer.modal.footer">
            <Link
              sx={{ color: "primary", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>

      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </DefaultDialog>
  );
}

export default function Breakout({
  redirectUrl,
  onConfirm,
  onDecline,
}: BreakoutProps) {
  const { t } = useTranslation();
  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  function onConfirmButtonClick() {
    trackUserInteraction({
      linkText: `IntercareBreakout | Continue`,
      linkIntent: "navigational",
      linkScope: "button",
    });
    onConfirm();
  }

  function onBackButtonClick() {
    onDecline();
  }

  function onViewDisclaimerClick() {
    setIsDisclaimerModalOpen(true);
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Breakout",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Breakout",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <Stack
      justifyContent="space-between"
      p={2}
      sx={{
        height: "inherit",
      }}
    >
      <Stack mt={6}>
        <IconLoader
          icon="UserWithFaceIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(70),
            alignSelf: "center",
          }}
        />

        <Stack spacing={5}>
          <Stack textAlign="center" spacing={2} py={4}>
            <Typography variant="h4" fontWeight={500} color="inherit">
              {t("IntercareBreakout.title")}
            </Typography>

            <Button onClick={onViewDisclaimerClick} variant="text" fullWidth>
              {t("common.viewDisclaimer")}
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <ButtonWithAnalytics
          page="IntercareBreakout"
          text={t("common.backButton")}
          intent="navigational"
          onClick={onBackButtonClick}
          variant="outlined"
          fullWidth
        >
          {t("common.backButton")}
        </ButtonWithAnalytics>
        <Button
          component="a"
          target="_blank"
          aria-label={t("common.continueButton")}
          href={redirectUrl}
          onClick={onConfirmButtonClick}
          fullWidth
        >
          {t("common.continueButton")}
        </Button>
      </Stack>

      <BreakoutDisclaimerModal
        isOpen={isDisclaimerModalOpen}
        onClose={() => setIsDisclaimerModalOpen(false)}
      />
    </Stack>
  );
}
