import FullscreenBackground from "@/components/FullscreenBackground";
import IconLoader from "@/components/IconLoader";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { convertPxToRem } from "@/utils";
import { Button, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

interface ThankYouProps {
  onButtonClick: () => void;
  buttonLabel: string;
  title: string;
  subTitle?: string;
}

export default function ThankYou({
  onButtonClick,
  buttonLabel,
  title,
  subTitle,
}: ThankYouProps) {
  function onHandleButtonClick() {
    onButtonClick();
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:User rating feedback success",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:User rating feedback success",
      pageCategory: "Medical assistance",
    });
  }, []);

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      textAlign="center"
      p={2}
      sx={{
        height: "100%",
      }}
    >
      <Stack spacing={4} py={8}>
        <IconLoader
          icon="ChatWithRatingIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />
        <Typography color="inherit" variant="h1">
          {title}
        </Typography>
        {subTitle && (
          <Typography color="inherit" variant="body1">
            {subTitle}
          </Typography>
        )}
      </Stack>

      <Button onClick={onHandleButtonClick} color="info" fullWidth>
        {buttonLabel}
      </Button>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
